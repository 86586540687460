.AccountPage-2 {
  > .MuiGrid-root {
    display: flex;
    justify-content: center;
  }
}
.DeviceChecks {
  margin-top: 2vh;
  margin-bottom: 15vh;
  display: flex;
  width: 900px;
  .camera-container {
    position: fixed;
    top: -200vh;
  }
  .left-column {
    width: 400px;
    padding-right: 100px;
    .status-box {
      margin: 4.5vh 0;
      padding: 1.5vh 2.4vw 1.5vw 1vw;
      border-radius: 0.6vw;
      position: relative;
      line-height: 1;
      text-align: left;
      font-size: 22px;
      display: flex;
      background: #F3F3F3;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .MuiCircularProgress-root {
        position: absolute;
        right: 1vw;
        $size: 17px;
        width: $size !important;
        height: $size !important;
      }
      .fail-icon,
      .check-icon {
        $size: 1.1vw;
        width: $size;
        height: $size;
        position: absolute;
        right: 1vw;
      }
      .fail-icon {
        color: red;
      }
      .check-icon {
        color: green;
      }
    }
  }
  .center-column {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 22px;
    padding-right: 0.8%;
  }
  .right-column {
    width: 400px;
    display: flex;
    align-items: center;
    .warning-box {
      border-radius: 0.5vw;
      padding: 0.7vh 0.6vw;
      font-size: 22px;
      text-align: left;
      background-color: #F3F3F3;
      border: 2px solid #E93042;
    }
  }
}

@media screen and (min-width: 860px) and (max-width: 1060px) {
  .DeviceChecks {
    width: 700px;
    .left-column {
      width: 350px;
      padding: 0;
    }
    .center-column {
      width: 30px;
    }
    .right-column {
      width: 350px;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 860px) {
  .DeviceChecks {
    width: 500px;
    margin-bottom: 1vh !important;
  }
}

// phone
@media screen and (max-width: 560px) {
  .DeviceChecks {
    height:calc(100vh - 8vh - 12vw - 3.5vh - 10vh - 13vw);
    width: 90vw;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    .left-column {
      padding-right: 0 !important;
      width: 100%;
      .status-box {
        width: auto;
        padding: 2vh 2vw;
        padding-right: 4vw;
        font-size: 3.8vw;
        .MuiSvgIcon-root {
          margin-right: 0vw;
          right: 1.4vw !important;
          $size: 5vw;
          width: $size;
          height: $size;
        }
      }
    }
    .center-column {
      display: none;
    }
    .right-column {
      width: 90vw;
      .warning-box {
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: 4.5vw;
      }
    }
  }
}