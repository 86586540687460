.FinalSteps {
  min-height: 400px;

  .tutorial-content-container {
    position: relative;
    height: calc(100vh - 12vh - 7vh - 28px - 24px);
  }

  .key-points-abs-container {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .key-points-container {
    position: relative;

    h2 {
      color: #E1E1E1;
      font-size: 12vh;
      margin: 0;
      margin-bottom: 1vh;
    }

    h3 {
      font-size: 5.5vh;
      margin: 0;
    }

    .text-container {
      display: flex;
      justify-content: center;

      p {
        text-align: left;
        width: 53vw;
        line-height: 1.5;
        font-size: 1.65vw;
      }
    }
  }

  .tutorial-stepper {
    padding-top: 14px;
    width: 50%;

    .MuiStepConnector-root {
      flex: unset !important;
    }

    .MuiStep-horizontal {
      padding: 0 !important;
      width: 8%;
      display: flex;
      justify-content: center;

      .MuiStepLabel-iconContainer {
        padding: 0 !important;
      }
    }

    .MuiStepConnector-root {
      width: 18%;
    }
  }

  .green-submit-button {
    background: #00703C;

    .MuiButton-label {
      text-transform: initial !important;
    }

    &:hover {
      background: #00703C;
    }
  }

  .tutorial-repeat-button {
    border: 2px solid #2259B3;
    background: white;
    color: #2259B3;

    &:hover {
      border: 2px solid #2259B3;
      background: white;
      color: #2259B3;
    }
  }

  .message {
    width: 690px;
    text-align: left;
  }

  .tutorial-video {
    background: #F3F3F3;
    width: auto;
    min-width: 65vw;
    height: 71vh;

    >div {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.buttonsRow {
  min-height: 300px;
}

.message {
  font-size: 25px;
}

@media screen and (min-width: 560px) and (max-width: 1060px) {
  .FinalSteps .message-content > div {
    font-size: 25px;
    width: 490px;
  }
}

@media screen and (min-width: 560px) and (max-height: 700px) {
  .FinalSteps .message .ft-35 {
    font-size: 30px !important;
  }
}

// phone
@media screen and (max-width: 560px) {
  .FinalSteps {
    .message {
      width: 88%;
      padding-left: 6vw;
    }
    .green-submit-button  {
      right: 6vw !important;
      width: 42vw;
      height: 13vw;
      font-size: 3.6vw !important;
      bottom: 5.011vh !important;

      &.back-button-v2 {
        right: unset !important;
        left: 6vw !important;
      }
    }
  }
}