.UserDetails {
  display: flex;
  justify-content: center;
  > div {
    text-align: left;
    margin-left: 2vw;
    width: 100%;
    .certificate-container {
      margin-bottom: 2vh;
      display: flex;
      align-items: center;
      .MuiFormControl-root {
        width: 70%;
        margin: 0;
        input {
          margin: 0;
        }
      }
      .link {
        width: 30%;
        margin-left: 24px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  .flex-inline {
    display: flex;
    align-items: center;

    .MuiButtonBase-root {
      padding-left: 0;
    }
    &.accept-condition-check .MuiButtonBase-root {
      padding-left: 9px;
    }
  }
}


.UpdateUserDetailsPage {
  width: 100%;
  display: flex;
  justify-content: center;
  > form {
    margin-left: 0;
    width: 70%;
    .UserDetails {
      width: 100% !important;
    }
  }
}

// phone
@media screen and (max-width: 560px) {  
  .UpdateUserDetailsPage {
    position: relative;
    .back-button,.save-button {
      bottom: 5vh !important;
    }
  }

  .UserDetails {
    .accept-condition-check {
      .font-light {
        margin-left: 3vw;
      }
    }
  }
}