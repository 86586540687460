// main big step
.MainSteps {
  margin-left: 14%;
  width: 72%;
}
.MainSteps .main-stepper {
  padding-top: 0;
  padding-bottom: 0;
  .MuiStepConnector-root,
  .MuiStepLabel-iconContainer {
    display: none;
  }

  .MuiStep-horizontal {
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 7vh;
    width: 20%;
    border-radius: 5vh;

    .MuiTypography-root {
      display: flex;
      align-items: center;
    }

    .MuiSvgIcon-root {
      width: 25px;
      height: 25px;
      margin-left: 0.2vw;
    }

    span {
      font-size: 22px;
    }

    .completed {
      color: #359C43;
    }

    &.active {
      width: 60%;
      z-index: 3;
      background: #2259B3;
      margin-left: -2.5vh;
      span {
        color: white;
      }
    }
    &.not-active {
      width: 35%;
      margin-left: -2.5vh;
      margin-right: -2.5vh;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    &.not-active-0 {
      margin-left: 0;
    }
    &.not-active-1 {
      width: 30%;
      padding-left: 2vw;
      &.MuiStep-completed {
        padding-left: 0;
        width: 35%;
        padding-right: 1vw;
      }
    }
    &.not-active-2 {
      margin-right: 0;
      >span {
        padding-left: 20%;
      }
    }
    &.finished {
      background: white;
      width: 40%;
      margin-left: -3.5vh;
      margin-right: -3.5vh;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    &.finish-0 {
      margin-left: 0;
    }
    &.finish-2 {
      margin-right: 0;
    }
    &.active-0 {
      margin-right: -3.5vh;
    }
    &.active-1 {
      margin-left: -3.5vh;
      margin-right: -3.5vh;
      width: 90%;
    }
    &.active-2 {
      margin-left: -2.5vh;
      width: 90%;
    }
    &.not-active-1.MuiStep-completed {
      padding-right: 2vh;
    }
    &.MuiStep-completed {
      background: white;
    }
    &.finish-2 {
      background: #2259B3;
      .MuiTypography-root {
        color: white;
      }
    }
  }
}

.MainSteps {
  .MuiStepIcon-root {
    color: #CAC9C9;
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #2259B3;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1700px) {
  .MainSteps {
    margin-left: 7vw;
    width: 84vw;
  }
}


@media screen and (min-width: 0px) and (max-width: 1500px) {
  .MainSteps .AccountCreation .MuiStep-horizontal  .MuiStepLabel-horizontal .MuiStepLabel-labelContainer .MuiTypography-root {
    display: none;
  }
}

@media screen and (min-width: 700px) and (max-width: 1300px) {
  .MainSteps .MuiStep-horizontal  .MuiTypography-root span {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .MainSteps .MuiStep-horizontal  .MuiTypography-root span {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .MainSteps .MuiStep-horizontal  .MuiTypography-root span {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 560px) and (max-width: 1500px) {
  .MainSteps {
    margin-left: 5vw;
    width: 90vw;
  }

  .MainSteps .main-stepper .MuiStep-horizontal {
    &.not-active-1 {
      width: 26%;
      padding-left: 8%;
    }
    &.active {
      width: 35% !important;
    }
    &.active-1 {
      width: 60% !important;
    }
  }

  .tutorial .tutorial-stepper {
    width: 32% !important;
  }
}

@media screen and (min-width: 560px) and (max-width: 1000px) {
  .MainSteps .MuiStepper-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .MainSteps .main-stepper .MuiStep-horizontal {
    &.not-active-0 {
      width: 40% !important;
    }
    &.active-1 {
      width: 35% !important;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 760px) {
  .MainSteps .main-stepper .MuiStep-horizontal.active.active-2 {
    width: 24% !important;
  }
  .MainSteps .main-stepper .MuiStep-horizontal.not-active.not-active-1.MuiStep-completed {
    width: 40% !important;
  }
}

// phone
@media screen and (max-width: 560px) {
  .MainSteps .main-stepper .MuiStep-horizontal.not-active.not-active-1.MuiStep-completed {
    span {
      width: 85%;
      padding-left: 0 !important;
    }
    .MuiSvgIcon-root {
      margin-left: 0 !important;
    }
  }

  .MainSteps {
    margin-left: 0;
    width: 100%;
  
    .MuiPaper-root {
      margin-left: 4%;
      width: 92%;
      padding: 0;
      .MuiStep-horizontal {
        min-height: unset;
        height: 12vw;
        padding: 0;
        border-radius: 5vw;
        span {
          line-height: 1;
          font-size: 3.8vw;
        }

        &.active {
          width: 33%;
        }
        &.not-active {
          width: 30% !important;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        &.not-active-1 {
          padding-left: 0;
          span {
            padding-left: 0 !important;
          }
        }
        &.not-active-2 {
          span {
            padding-left: 0 !important;
          }
        }
        .MuiSvgIcon-root {
          $size: 6vw;
          width: $size;
          height: $size;
          margin-left: 1vw;
        }

        &.first-step {
          margin-left: 0!important;
        }
        &.first-step.MuiStep-completed {
          padding-right: 4vw;
          width: 36%;
        }
        &.active-1 {
          width: 35%;
        }
        &.finish-1 {
          margin-left: 0;
          margin-right: 9vw;
        }
      }
    }

    // tutorial
    .MuiPaper-root.stepper-step-0 {
      .MuiStep-horizontal {
       &.active-0 {
         width: 40%;
       }
       &.not-active-1 {
         width: 35%;
         span {
           padding-left: 1vw;
         }
       }
       &.not-active-2 {
         width: 40%;
       }
      }
    }
    // account creation
    .MuiPaper-root.stepper-step-1 {
      .MuiStep-horizontal {
        &.not-active-0 {
          width: 37.5%;
        }
        &.active-1 {
          width: 34%;
        }
        &.not-active-2 {
          width: 41.5%;
        }
      }
    }
    // recording
    .MuiPaper-root.stepper-step-2 {
      .MuiStep-horizontal {
        &.not-active-0 {
          width: 35%;
        }
        &.not-active-1 {
          width: 34% !important;
          span {
            width: 97% !important;
          }
        }
        &.active-2 {
          width: 40%;
        }
      }
    }
    .MuiPaper-root.stepper-step-3 {
      margin-left: 8vw !important;
      .MuiStep-horizontal {
        &.finish-0 {
          width: 35% !important;
          padding-right: 4vw !important; 
        }
        &.finished.finish-1 {
          width: 34%;
          padding-right: 2vh !important;
        }
      }
    }
  }
}

.phone-stepper {
  .MuiStep-root {
    margin-left: -2vw !important;
    margin-right: -2vw !important;
  }
  .first-step.MuiStep-completed {
    padding-right: 0 !important;
  }
  .MuiStep-horizontal.not-active-1.MuiStep-completed {
    padding-right: 0 !important;
  }
  .active {
    width: 45vw !important;
  }
}