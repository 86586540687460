.CheckOut {
  width: 100%;
  padding-top: 2vh;
  padding-left: 22%;
  margin-bottom: 19vh;
  text-align: left;
  line-height: 1.9;

  .margin-bottom-r6 {
    margin-bottom: 17px;
  }

  .green-submit-button  {
    background: #00703C !important;
  }
  .apply-btn {
    background: #00703C !important;
  }
  .label-box {
    display: flex;
    .label {
      width: 330px;
    }
  }
  input {
    padding: 1vh 1vw;
    border-radius: 1vh;
    background: #F3F3F3;
    width: 160px;
    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .accept-condition-check {
    .MuiCheckbox-root {
      margin-left: -13.5px !important;
    }
  }

  .accept-condition-check.invalid {
    color: red !important;
    span {
      color: red !important;
    }
    .MuiCheckbox-root {
      color: red !important;
    }
  }

  button {
    margin-left: 2vw;
    border: none;
    outline: none;
    color: white;
    border-radius: 1.5vw;
    padding: 1vh 1vw;
    background: #2259B3;
  }

  .flex-inline {
    margin: 1vh 0;
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      width: 2vw;
      height: 2vw;
    }
  }
  .price-amount {
    margin-top: 1vh;
    margin-bottom: 1.2vh;
  }
  .voucher-row {
    margin-bottom: 2vh;
    input,
    button {
      padding: 0 1.2vw;
      line-height: 1.5;
      min-height: 5.2vh;
    }
    button {
      cursor: pointer;
    }
  }
  .pay-card-row {
    display: flex;
    img {
      margin-left: 10px;
      margin-right: 10px;
      height: 32.58px;
    }
    .card-box {
      padding: 1.5vh 1vw;
      border-radius: 1vh;
      background: #F3F3F3;
      width: 340px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
    .desktop-inline-row {
      padding-top: 1vh;
      display: flex;
    }
  }
  .text-box {
    width: 600px;
    margin-top: 3vh;
    line-height: 1;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1500px) {
  .CheckOut {
    padding-left: 27%;
  }
}

@media screen and (min-width: 700px) and (max-width: 1060px) {
  .CheckOut {
    padding-top: 2vh;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 27vh;
  }

  .CheckOut .label-box .label {
    width: 50vw;
  }
}


// phone
@media screen and (max-width: 700px) {
  .CheckOut {
    padding: 0 !important;
    .label-box {
      .label {
        width: 60vw;
      }
    }

    input {
      height: 6vh;
      padding: 0 2vw;
      width: 40vw;
    }
  
    button {
      margin-left: 7vw;
      border-radius: 6vw;
      height: 6vh;
      padding: 0 10vw;
    }

    .flex-inline {
      display: flex;
      align-items: center;

      .MuiButtonBase-root {
        padding-left: 0;
      }
  
      .MuiSvgIcon-root {
        width: 12vw;
        height: 12vw;
      }
    }
    .card-box {
      padding: 2vh 2vw;
      width: 88vw;
    }
    .text-box {
      width: 90vw;
      font-size: 4vw;
    }
  }
}

@media screen and (min-width: 560px) {
  .checkout-button-row {
    position: relative;

    button {
      right: 5.5vw !important;
    }
  }
}
 
// phone
@media screen and (max-width: 560px) {
  .AccountPage-4 {
    .green-submit-button {
      bottom: 5vh !important;
    }
  }
  .CheckOut {
    padding-bottom: 0vh !important;
    margin-top: 2vh;
    margin-left: 4vw;
    margin-bottom: 0 !important;
    width: 92vw;
    .price-amount {
      display: flex;
      align-items: center;
      height: 5.5vh;
    }
    .text-box {
      margin-top: 1vh;
      line-height: 1.2;
    }
    .voucher-row {
      input {
        padding-left: 4vw;
        width: 50vw;
      }
      button {
        border-radius: 5.5vh;
        width: 29vw;
      }
    }
    .pay-card-row {
      display: block;
      > div {
        display: inline;
      }
      .card-box {
        display: block;
        width: calc(100% - 2.3vw) !important;
      }
      .desktop-inline-row {
        margin-top: 2vh;
      }
      img {
        display: inline;
      }
    }
    .accept-condition-check {
      .MuiIconButton-label {
        input {
          width: 8vw !important;
          height: 8vw !important;
        }
      }
      .MuiCheckbox-root {
        margin-left: -1vw !important;
      }
      .MuiSvgIcon-root {
        width: 8vw !important;
        height: 8vw !important;
      }
    }
  }
  .AccountPage-4.ActiveStep-1 {
    height: auto !important;
    > .MuiGrid-root {
      height: auto !important;
    }
    .checkout-button-row {
      position: initial;
      display: flex;
      justify-content: flex-end;
    }
    .green-submit-button {
      bottom: unset !important;
      position: relative !important;
    }
  }

  .CheckOut .green-submit-button {
    margin-top: 2vh;
    position: relative !important;
    bottom: unset !important;
    right: unset !important;
    margin-left: 30vw;
  }
}
