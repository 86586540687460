.UserDetails {
  min-height: 50vh;
  width: 100%;
  max-width: 84vw;
  .save-user-details {
    svg {
      background: #00703C;
      color: white;
    }
  }
  .label-r5 {
    margin-bottom: 2vh;
  }
  .accept-condition-check {
    margin-top: 2vh;
    .MuiIconButton-root {
      margin-left: -11px !important;
    }
  }
  .details-box {
    display: flex;
    justify-content: center;
    max-width: 84vw;
    margin-top: 4vh;
  }
  .MuiFormControl-root {
    margin-top: 2vh;
    $width: 400px;
    width: calc(#{$width} * 1.2) !important;
    max-width: 100%;
    &.user-shorter-field {
      width: $width !important;
    }
  }
  .MuiFormLabel-root {
    left: 0;
    text-align: left;
    transform: unset !important;
    width: 100%;
    color: black;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 22px !important;
  }

  .input-label {
    font-weight: 600;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 22px !important;
  }

  .date-of-birth {
    margin-top: 0 !important;
    input {
      margin-top: 0 !important;
    }
  }

  .MuiInputBase-root {
    input {
      font-family: 'Montserrat', sans-serif;
      margin-top: 23px;
      padding: 1vh 1vw;
      min-height: 3.4vh;
      font-size: 18px;
      border-radius: 1vh;
      background: #F3F3F3;
    }
    &.MuiOutlinedInput-adornedEnd {
      padding: 0;
    }
    fieldset{
      border: none;
    }
    .MuiInputAdornment-root {
      margin-top: 23px;
      margin-left: 0px;
    }
  }

  .line-address {
    width: 80%;
  }
  
  .second-line-address {
    margin-top: 0 !important;
  }
}

//desktop
@media screen and (min-width: 860px) {
  .UserDetails {
    .details-box {
      align-items: flex-start;
      > div {
        justify-content: flex-end;

        &.start {
          padding-right: 40px;
        }
  
        &.end {
          padding-left: 40px;
          justify-content: flex-start;
        }
      }
      .user-shorter-field.postcode {
        width: 217px !important;
      }
    }
  }
}

//desktop not high make font smaller
@media screen and (min-width: 860px) and (max-height: 800px) {
  .UserDetails {
    .input-label,
    .MuiFormLabel-root {
      font-size: 13px !important;
    }
    .MuiInputBase-root input {
      margin-top: 13px !important;
      font-size: 16px !important;
      
    }
    .MuiInputBase-root  {
      .MuiInputAdornment-root{
        margin-top: 13px;
      }
    }
    .date-of-birth input {
      margin-top: 0 !important;
    }
  }
}
 
@media screen and (min-width: 860px) and (max-width: 1060px) {
  .UserDetails {
    width: 100%;
     max-width: 84vw;

    .MuiFormLabel-root  {
      font-size: 18px !important;
    }
    .MuiFormControl-root {
      margin-top: 2vh;
      $width: 250px;
      width: calc(#{$width} * 1.2) !important;
      max-width: 100%;
      &.user-shorter-field {
        width: $width !important;
      }
      .MuiInputAdornment-root {
        margin-top: 2vh;
      }
    }
  }
}

@media screen and (min-width: 1060px) and (max-width: 1560px) {
  .UserDetails {
    max-width: 100vw !important;
    form {
      width: 70% !important;
    }
  }
}

@media screen and (min-width: 860px) and (max-width: 1340px) {
  .UserDetails {
    max-width: 100vw !important;
    form {
      width: 80% !important;
    }
  }
}

@media screen and (min-width: 860px) and (max-width: 1140px) {
  .UserDetails {
    max-width: 100vw !important;
    form {
      width: 100% !important;
    }
  }
}

// phone
@media screen and (max-width: 860px) {  
  .UserDetails {
    padding-bottom: 2vw;
    width: 100%;
    max-width: 100%;
    .details-box {
      display: block !important;
      margin-left: 0vw;
      padding: 0 3vw;
      margin-top: 2vh;
      > div {
        margin-left: 0;
        width: 100%;

        .MuiFormLabel-root {
          font-weight: normal;
          font-size: 16px !important;
        }

        .input-label {
          font-weight: normal;
          font-size: 16px !important;
        }

        .MuiFormControl-root {
          width: 100% !important;
          max-width: 100%;
          input {
            font-size: 14px;
          }
        }
      }
    }
    .accept-condition-check {
      .MuiIconButton-root {
        margin-left: -4px !important;
      }
    }
  }
}
