.ForgotPasswordSet {
  width: 100%;
  display: flex;
  justify-content: center;

  >div {
    margin-top: 2vh;
    text-align: left;
    width: 700px;

    .title {
      margin-bottom: 3vh;
    }

    .email-container {
      width: 370px;
      margin-top: 2vh;
      margin-bottom: 3vh;
    }

    input {
      line-height: 1.5;
      font-size: 18px;
      padding: 1vh 1vw;
      padding-top: 1vh;
      padding-right: 1vw;
      padding-bottom: 1vh;
      padding-left: 1vw;
    }
  }
}