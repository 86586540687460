.tutorial {
  video {
    width: auto !important;
    aspect-ratio: 1.78125 !important;
    max-width: 84vw !important;
    height: 71vh !important;
  }

  min-height: 400px;

  .react-player__preview {
    min-width: 125.5vh;
  }

  .tutorial-content-container {
    position: relative;
    padding-left: calc(3.8vw + 24px);
    padding-right: calc(3.8vw + 24px);
    height: calc(100vh - 12vh - 7vh - 28px - 24px);
  }

  .key-points-abs-container {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100% !important;
    width: 100%;
    display: flex;
    justify-content: center;

    >div {
      max-width: 125vh;
    }
  }

  .key-points-container {
    position: relative;
    display: flex;
    align-items: center;
    height: calc(71vh - 67px - 10vh - 3vh);

    h2 {
      color: #E1E1E1;
      font-size: 12vh;
      margin: 0;
      margin-bottom: 1vh;
    }

    h3 {
      font-size: 5.5vh;
      margin: 0;
    }

    .text-container {
      display: flex;
      justify-content: center;

      p {
        text-align: left;
        width: 53vw;
        line-height: 1.5;
        font-size: 1.65vw;
      }
    }
  }

  .tutorial-stepper {
    margin-left: 1vw;
    padding-top: 14px;
    width: 45%;

    .MuiStepConnector-root {
      flex: unset !important;
    }

    .MuiStep-horizontal {
      padding: 0 !important;
      width: 8%;
      display: flex;
      justify-content: center;

      .MuiStepLabel-iconContainer {
        padding: 0 !important;
      }
    }

    .MuiStepConnector-root {
      width: 18%;
    }
  }

  .green-submit-button {
    background: #00703C;

    .MuiButton-label {
      text-transform: initial !important;
    }

    &:hover {
      background: #00703C;
    }
  }

  .tutorial-repeat-button {
    border: 2px solid #2259B3;
    background: white;
    color: #2259B3;

    &:hover {
      border: 2px solid #2259B3;
      background: white;
      color: #2259B3;
    }
  }

  .skip-tutorial-button {
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  .message {
    font-size: 35px;
    width: 690px;
    text-align: left;
  }

  .tutorial-video {
    background: #F3F3F3;
    height: 71vh;

    >div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .skip-video-tutorial {
    background: red;
  }
}

.buttonsRow {
  min-height: 300px;
}

.message {
  font-size: 25px;
}

@media screen and (min-width: 1060px) and (max-width: 1500px) {
  .tutorial .key-points-abs-container {
    max-width: calc(100vw - 10vw - 7.6vw - 48px);

    .key-points-container {
      padding-top: 3vh;

      h2 {
        font-size: 77px;
      }

      p {
        font-size: 1.9vw;
      }
    }
  }

  .tutorial {
    .react-player__preview {
      min-width: calc(100vw - 28vw - 7.6vw - 48px);
      max-width: 100vw !important;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 1060px) {
  .tutorial .tutorial-content-container .message-container .message {
    font-size: 25px;
    width: 490px;
  }

  .tutorial .key-points-abs-container {
    width: 100%;

    >div {
      max-width: calc(100vw - 10vw - 7.6vw - 48px);
    }

    .key-points-container {
      h2 {
        font-size: 40px;
      }

      .text-container {
        width: 50vw;
        padding-left: 1vw;
        padding-right: 1vw;

        p {
          font-size: 18px;
        }
      }
    }
  }

  .tutorial {
    .green-submit-button {
      right: 10vw !important;
    }

    .tutorial-repeat-button {
      left: 10vw !important;
    }

    .react-player__preview {
      min-width: calc(100vw - 10vw - 7.6vw - 48px);
      max-width: 100vw !important;
    }
  }
}

// phone
@media screen and (max-width: 560px) {
  .tutorial .react-player__preview {
    min-width: calc(100vw - 10vw - 7.6vw - 48px);
    max-width: 100vw !important;
  }


  .tutorial {
    .key-points-abs-container {
      width: 100%;

      >div {
        max-width: 100vw;
      }
    }

    .tutorial-content-container {
      height: calc(100vh - 8vh - 12vw - 3.5vh);
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .key-points-container {
      height: 110vw;
      position: relative;

      h2 {
        color: black;
        font-size: 11vw !important;
        margin: 0;
        margin-top: 4vh;
        margin-bottom: 1vh;
      }

      h3 {
        font-size: 7vw;
        margin: 0;
      }

      .text-container {
        display: flex;
        width: 90vw !important;
        justify-content: center;

        p {
          text-align: left;
          width: 90vw;
          line-height: 1.2;
          font-size: 5.4vw;
        }
      }
    }

    .message {
      max-width: 100%;
      padding-left: 6vw;
      font-size: 5vw;
    }

    .message-container {
      display: block !important;
      max-width: 100% !important;
      flex-basis: unset !important;
    }

    .green-submit-button,
    .tutorial-repeat-button {
      width: 42vw;
      height: 13vw;
      font-size: 3.6vw !important;
      bottom: 5.011vh !important;

      span.MuiButton-label {
        text-transform: uppercase !important;
      }
    }

    .tutorial-repeat-button {
      left: 5vw !important;
    }

    .green-submit-button {
      right: 5vw !important;
      background: #00703C !important;
    }

    .tutorial-video {
      margin-top: 1.5vh !important;
      width: 100vw;
      height: auto;
      height: 133vw;

      .react-player__shadow {
        width: 30vw !important;
        height: 30vw !important;
      }
    }

    video {
      min-width: 100vw !important;
      min-height: 133vw;
      height: auto !important;
      max-width: 100vw !important;
    }
  }

  .MuiButtonBase-root {
    padding: 0 !important;
  }

  .tutorial .tutorial-content-container > div {
    align-items: flex-start;
    height: 100%;
  }

  .tutorial .tutorial-content-container .message-container {
    display: flex !important;
    height: calc(100vh - 8vh - 12vw - 3.5vh - 10vh - 13vw);
    align-items: center;
  }

  .tutorial .tutorial-content-container .message-container .message {
    margin-top: 0vh;
    padding-left: 0;
    font-size: 20px;
    width: 92vw;
    max-width: 92vw !important;
  }

  .MuiBox-root-2 {
    height: 0 !important;
  }
}

@media (min-aspect-ratio: 10/5) {
  .tutorial .key-points-abs-container .key-points-container p {
    margin: 0;
    margin-top: 1vh;
    font-size: 1.3vw;
  }
}