.RecSign {
  position: absolute;
  z-index: 10;
  top: 5px;
  left: 5px;
  display: flex;
  align-items: center;
  color: red;
  > div {
    background: red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  font-size: 14px;
}