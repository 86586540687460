.ForgotPassword {
  width: 100%;
  display: flex;
  justify-content: center;

  >div {
    margin-top: 2vh;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    width: 450px;

    .title {
      margin-bottom: 3vh;
    }

    .email-container {
      width: 370px;
      margin-top: 2vh;
      margin-bottom: 3vh;
    }

    input {
      line-height: 1.5;
      font-size: 18px;
      padding: 1vh 1vw;
      padding-top: 1vh;
      padding-right: 1vw;
      padding-bottom: 1vh;
      padding-left: 1vw;
    }
  }
}

// phone
@media screen and (max-width: 560px) {
  .ForgotPassword {
    padding-left: 6vw;
    padding-right: 6vw;
    width: calc(100vw - 12vw);

    .UserPassword {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .UserPassword .MuiFormControl-root {
      width: calc(100vw - 12vw) !important;
    }

    > div {
      padding: 0; 
      width: calc(100vw - 12vw);
    }
    .email-container,
    input {
      font-size: 14px !important;
      max-width: calc(100vw - 18vw) !important;
    }
    button {
      right: unset !important;
      bottom: unset !important;
      width: 42vw;
      height: 13vw;
      font-size: 3.6vw !important;

      span.MuiButton-label {
        text-transform: uppercase !important;
      }
    }
  }
}