.UserAccount {
  display: flex;
  justify-content: center;

  >div {
    width: 880px;
    text-align: left;

    .upper-container {
      margin: 0 4%;
    }

    .title {
      margin-bottom: 1vh;
    }

    .second-title {
      margin: 3vh 0 3vh 0;
      text-align: center;
    }

    svg {
      fill: #2259B3;
    }
  }
}

@media screen and (max-width: 560px) {
  .UserAccount {
  }
}