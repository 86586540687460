
    #uploading {
      justify-content: center;
      align-items: center;
      position: absolute;
      display: flex;
      width: 100%;
      height: auto;
      z-index: 99999;
      >div {
        z-index: 99999;
        margin-top: 4vh;
        $size: 52vh;
        display: flex;
        height: $size;
        max-height: 40vw;
      }
    }