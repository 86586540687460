.DoanloadCertificate {
  display: flex;
  justify-content: center;
  > div {
    text-align: left;
    width: 960px;
    .certificates {
      height: calc(100vh - 12vh - 110px - 10vh - 67px);
      max-height: calc(100vh - 12vh - 100px - 10vh - 67px);
      overflow-y: auto;
    }
    .certificate-container {
      margin-bottom: 2vh;
      display: flex;
      align-items: center;
      .MuiFormControl-root {
        width: 70%;
        margin: 0;
        input {
          margin: 0;
        }
      }
      .link {
        margin-left: 24px;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .certificateLabel {
        margin-left: 24px;
        margin-right: 24px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .DoanloadCertificate {
    .certificates {
      height: calc(100vh - 8vh - 100px - 5vh - 13vw);
      max-height: calc(100vh - 8vh - 100px - 5vh - 13vw);
      overflow-y: auto;
    }
    .upper-text {
      font-size: 17px !important;
      margin: 0 4%;
    }
  }
}