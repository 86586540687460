.Footer {
  margin-top: 10px;
  padding: 20px;
  color: white;
  background: #2259B3;
  text-align: left;
  display: flex;
  line-height: 1.45;
  justify-content: center;
  font-size: 14px;

  .footer-inside {
    max-width: 1100px;

    .first-container {
      display: flex;

      .first-column {
        margin-top: 19px;
        width: 25%;

        .logo-container {
          margin-left: 20px;
          margin-bottom: 35px;

          img {
            margin-left: 17px;
            width: 10.5vw;
          }
        }

        .l-text {
          margin: 20px;
        }

        .l-first {
          margin-bottom: 30px;
        }
      }

      .second-column {
        margin: 20px;

        a {
          line-height: 1;
          text-transform: capitalize;
          padding: 14px;
          text-decoration: initial;
          color: white;
          display: block;
        }
      }
    }

    .separator {
      margin: 20px;
      margin-top: 19px;
    }

    .last-text {
      line-height: 1.45;
      margin: 20px;
      margin-bottom: 30px;
    }
  }
}


// phone
@media screen and (max-width: 560px) {
  .Footer {
    padding: 0;

    .footer-inside {
      .first-container {
        display: block;

        .first-column {
          margin-top: 35px;
          width: 100%;

          img {
            width: 30vw !important;
          }
        }
      }

      .last-text {
        margin-bottom: 50px;
      }
    }
  }
}