.toggle-button-container-r4 {
  display: flex;
  .toggle-button-r4 {
    cursor: pointer;
    font-size: 1.125rem;
    padding: .75rem 1.875rem;
    margin-left: 0;
    border: 3px solid #4bc592;
    color: black;
    font-weight: bold;
    border-radius: 50px;
    background: #fdfaf2;
    font-family: Arial;
    transition: all 200ms ease-in-out;
   
    &:hover {
      color: #4bc592;
      border: 3px solid #078192;
    }

    &.active {
      background: #4bc592;

      &:hover {
        color: white;
        background: #078192;
      }
    }

    &.first-btn-r4 {
      margin-right: 15px;
    }
  }
}

// phone
@media screen and (max-width: 560px) {
  .toggle-button-container-r4 {
    .toggle-button-r4 {
      padding: 2vw 8vw !important;
      height: calc(6vw + 4vw) !important;
    }
  }
}