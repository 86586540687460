.App {
  text-align: center;
  width: 100%;
  font-family: 'Montserrat', sans-serif;

  button,
  input,
  .MuiTypography-h5,
  span {
    font-family: 'Montserrat', sans-serif;
  }

  .link {
    width: 100%;
  }
}

.MuiCheckbox-root{
  .MuiSvgIcon-root{
    font-size: 48px !important;
  }
}


.blue {
  color: #2259B3;
}

.MuiLinearProgress-bar,
.MuiLinearProgress-barColorPrimary {
  background: #2259B3 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.phone {
  display: none !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #2259B3 !important;
}

.MuiStepIcon-text {
  font-family: 'Montserrat', sans-serif !important;
}

.tutorial-repeat-button,
.green-submit-button {
  z-index: 30;
  text-transform: initial !important;
  font-size: 20px !important;
  width: 210px;
  height: 67px;
  padding: 0 !important;
  border-radius: 33.5px !important;
}

.green-submit-button {
  .MuiCircularProgress-root {
    position: absolute;
    margin-left: 10px;
    top: 20px;
    width: 25px !important;
    height: 25px !important;
  }
}

.green-submit-button:hover {
  background: #00703C !important;
}

.MuiStepLabel-iconContainer {
  padding: 0 !important;

  .MuiStepLabel-iconContainer {
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
  }

  .MuiStepIcon-text {
    margin-top: 12px;
    font-size: 20px;
    transform: translate(0px, 3px);
    -ms-transform: translate(0px, 3px);
    /* IE 9 */
    -webkit-transform: translate(0px, 3px);
    /* Safari and Chrome */
    -o-transform: translate(0px, 3px);
    /* Opera */
    -moz-transform: translate(0px, 3px);
    /* Firefox */
  }
}

// general styles
.ft-35 {
  font-size: 35px;
}

.ft-25 {
  font-size: 25px;
}

.ft-22 {
  font-size: 22px;
}

.ft-20 {
  font-size: 20px !important;
}

.ft-18 {
  font-size: 18px;
}

.font-light {
  font-weight: 300;
}

.link {
  cursor: pointer;
  color: #2259B3;
}

.underline {
  text-decoration: underline !important;
}

.no-underline {
  text-decoration: unset !important;
}

.text-right {
  text-align: right !important;
}

.base-field {
  &.MuiFormControl-root {
    margin-top: 4vh;
    margin-bottom: 1.5vh;
    width: 100%;
  }

  .MuiFormLabel-root {
    left: -5px;
    top: -0.5vh;
    color: black;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px !important;
  }

  .MuiInputLabel-outlined {
    transform: translate(5px, 0) scale(1) !important;
  }

  input {
    font-family: 'Montserrat', sans-serif;
    margin-top: 23px;
    padding: 1vh 1vw;
    min-height: 3.4vh;
    font-size: 20px;
    border-radius: 1vh;
    background: #F3F3F3;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .MuiInputAdornment-root,
  fieldset {
    display: none;
  }
}

.back-button,
.save-button {
  font-size: 20px !important;
  border-radius: 33px !important;
  width: 214px;
  height: 67px;
  text-transform: capitalize !important;
}


.save-button {
  background: #2259B3 !important;
}

.back-button {
  border: 2px solid #2259B3 !important;
  background: white !important;
  color: #2259B3 !important;

  &:hover {
    border: 2px solid #2259B3;
    background: white;
    color: #2259B3;
  }
}

.cards-content {
  margin-top: 2.5vh;
  display: flex;
  justify-content: center;

  .card {
    cursor: pointer;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 31.3%;
    margin-left: 1%;
    margin-right: 1%;

    .card-text-block {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 140px;
    }

    p {
      margin: 0;
      color: black;
    }

    .btn-container {
      display: flex;
      justify-content: center;
      color: #2259B3;

      svg {
        $size: 58px;
        width: $size;
        height: $size;
      }
    }
  }
}

.MainSteps .MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

@media screen and (max-width: 560px) {
  .cards-content {
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    .card {
      min-height: 48vw;
      width: 50%;
      .MuiCardContent-root {
        padding: 0 !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        .card-text-block {
          height: 30vw !important;
        }
      }
    }
  }
}

// phone
@media screen and (max-width: 560px) {
  .ft-35 {
    font-size: 20px;
  }

  .ft-25 {
    font-size: 17px;
  }

  .ft-22 {
    font-size: 15px;
  }

  .ft-20 {
    font-size: 14px !important;
  }

  .ft-18 {
    font-size: 14px !important;
  }

  .green-submit-button {
    .MuiCircularProgress-root {
      position: absolute;
      margin-left: 10px;
      top: 12px;
      width: 21px !important;
      height: 21px !important;
    }
  }

  .App {
    .desktop {
      display: none !important;
    }

    .phone {
      display: block !important;
    }

    .MuiLinearProgress-root {
      margin-left: 4%;
      width: 92%;
    }

    .phone-tutorial-progress {
      margin-top: 1vh;
      height: 2.5vh;
      background: white;
      border-radius: 0.8vh;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  .base-field {
    &.MuiFormControl-root {
      margin-top: 14px;
      margin-bottom: 14px;
    }

    .MuiFormLabel-root {
      font-size: 15px !important;
    }

    input {
      padding-left: 4vw;
      padding-right: 4vw;
      margin-top: 18px;
      font-size: 14px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 2px 2px 2px 30px #f3f3f3 inset !important;
}

.MuiFormHelperText-root.MuiFormHelperText-contained {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
}

@media screen and (min-width: 0px) and (max-width: 860px) {

  .DoanloadCertificate,
  .UpdatePaymentDetails,
  .UpdateUserPassword,
  .UpdateUserDetailsPage {
    .back-button {
      left: 5vw !important;
    }

    .save-button {
      right: 5vw !important;
    }
  }

  .UpdateUserDetailsPage {
    padding-bottom: calc(6vh + 67px) !important;

    form {
      width: 100% !important;
    }

    .back-button,
    .save-button {
      bottom: -10px !important;
    }
  }
}


@media screen and (min-width: 0px) and (max-width: 560px) {

  .back-button,
  .save-button {
    font-size: 18px !important;
    border-radius: 24px !important;
    bottom: 5.011vh !important;
    width: 42vw;
    height: 13vw;
    text-transform: capitalize !important;
  }

  .save-button {
    right: 5vw !important;
  }

  .back-button {
    left: 5vw !important;
  }
}

// phone videos
@media screen and (max-width: 560px) {
  .ScreenChecks > div > video {
      width: 100vw !important;
      height: auto !important;
      max-height: unset !important;
  }
  .ScreenChecks #circle {
    height: 70% !important;
  }
}

// green button
@media screen and (min-width: 0px) and (max-width: 1060px) {
  .Recording {
    .green-submit-button {
      right: 6vw !important;

      &.repeat-button {
        left: 6vw !important;
      }
    }
  }
}


// video spinner
@media screen and (min-width: 0px) and (max-width: 1060px) {
  .uploading-circle {
    width: 30vw !important;
    height: 30vw !important;
    &.last-spinner {
      margin-top: 10vh;
    }
  }
}

.RotatePopup {
  .MuiDialog-paperWidthSm {
    margin: 0 !important;
    border-radius: 4px;
    width: 85vw;
    min-height: 40vh;
    padding-left: 3vw;
    padding-right: 3vw;
    min-width: unset !important;
    max-width: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-aspect-ratio: 12/5) {
  body {
    .tutorial .message,
    .ft-35 {
      font-size: 25px !important;
    }
  }
}

