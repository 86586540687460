.Header {
  height: 12vh;
  display: flex;
  align-items: center;
  font-size: 18px;
  .phone {
    display: none;
  }

  .logo {
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    text-decoration: initial;
    img {
      margin-left: 38px;
      width: 14.8vw;
    }
  }
  .right-menu {
    width: 66.5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      cursor: pointer;
      margin: 0 2vw;
      color: black;
      text-decoration: initial;
    }

    .log-in-btn {
      position: absolute !important;
      right: 4%;
      color: white;
      display: flex;
      text-transform: capitalize;
      align-items: center;
      justify-content: center;
      background: #2259B3;
      border-radius: 3.5vh;
      width: 140px;
      height: 67px;
      padding: 0;
    }
  }
}

@media screen and (min-width: 1060px) and (max-width: 1500px) {
  .Header .right-menu .log-in-btn {
    width: 120px !important;
    font-size: 18px !important;
    right: 3% !important;
  }
}

@media screen and (min-width: 560px) and (max-width: 1060px) {
  .Header .logo {
    img {
      margin-left: 4px;
      width: 25vw;
    }
  }
  .Header .right-menu {
    width: 60%;
    font-size: 12px;
  }
  .Header .right-menu .log-in-btn {
    width: 80px !important;
    font-size: 14px !important;
    height: 50px;
    right: 2% !important;
  }
}

@media screen and (min-width: 560px) and (max-width: 860px) {
  .Header .right-menu .log-in-btn {
    width: 70px !important;
    font-size: 12px !important;
    height: 40px;
  }
}

.LogoutPopup {
  .MuiDialog-paperWidthSm {
    box-shadow: 0px 3px 6px #00000029;
    min-width: 1304px !important;
  }
  .MuiDialogTitle-root {
    padding: 0 0;
    height: 73vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiTypography-root {
      font-family: 'Montserrat', sans-serif;
      font-size: 35px;
      width: 720px;
    }
  }
}


.update-success-dialog .MuiPaper-root {
  min-width: 550px !important;
  min-height: 45vh !important;
  .MuiDialogTitle-root {
    margin-top: 5vh;
    height: 25vh;
  }
  .MuiTypography-root {
    width: 365px;
    font-size: 25px !important;
  }

  button {
    margin-bottom: 8vh;
  }
}

// phone
@media screen and (max-width: 560px) {
  .Header {
    height: 8vh;
    margin-left: 4%;
    .desktop {
      display: none;
    }
    .phone {
      display: block;
    }
    .logo {
      text-align: left;
      font-size: 3vh;

      img {
        margin-left: 0;
        width: 36vw;
      }
    }
    .right-menu.phone {
      width: 60vw;
      display: flex !important;
      justify-content: flex-end;

      svg {
        $size: 5vh;
        width: $size;
        height: $size;
      }
    }
  }
  #main-menu {
    .MuiPaper-root {
      position: fixed;
      width: 80%;
      top: 0 !important;
      left: 0 !important;
      max-height: 100% !important;
      height: 100vh;

      .close-icon {
        position: absolute;
        height: 5vh;
        width: 5vh;
        top: 5vh;
        right: 10vw;
      }
      .menu-list {
        padding-left: 6vw;
        padding-top: 15vh;
        color: #A2ABB3;
        text-transform: uppercase;
        .MuiButtonBase-root {
          font-size: 15px !important;
        }
      }
    }
  }

  
  .LogoutPopup {
    .MuiDialog-paperWidthSm {
      margin: 0 !important;
      border-radius: 4px;
      width: 85vw;
      padding-left: 3vw;
      padding-right: 3vw;
      min-width: unset !important;
      max-width: unset !important;
    }
    .MuiDialogTitle-root {
      padding: 0 0;
      height: 73vh;
      .MuiTypography-root {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
      }
    }
  }
}


// phone
@media screen and (max-width: 560px) {  
  .update-success-dialog .MuiPaper-root {
    min-width: 88vw !important;

    .MuiTypography-root {
      padding-left: 5vw;
      padding-right: 5vw;
      font-size: 20px !important;
      width: 100%;
    }
    button {
      margin-bottom: 0 !important;
    }
  }
}