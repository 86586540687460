.SetUserDetails {
  .set-details-box {
    padding-top: 3vh;
    margin-left: 1vw !important;
    display: flex;
    .column {
      display: flex;
      justify-content: center;
      width: 50%;
      > div {
        width: 400px;
      }
    }
  }
  .green-submit-button  {
    background: #00703C !important;
  }
  .label-r5 {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 860px) {
  .SetUserDetails {
    position: relative;
    .set-details-box {
      padding-bottom: calc(22vw + 8vh);
      display: block;
      .column {
        width: 100%;
      }

      .input-label {
        font-size: 17px !important;
      }
      .MuiFormLabel-root {
        font-size: 17px !important;
      }
      .MuiInputBase-root input {
        font-size: 14px !important;
      }

      .MuiFormHelperText-contained {
        margin-left: 0 !important;
      }
    }

    .green-submit-button {
      bottom: 8vh;
      height: 13vw !important;
      right: 5vw !important;
      font-size: 3.6vw !important;
      line-height: 1.75;
      width: 42vw;
      background: #00703C !important;
    }
  }
}

// phone
@media screen and (max-width: 560px) {
  .SetUserDetails {
    .set-details-box {
      margin-left: 0 !important;
      padding-left: 4vw;
      padding-right: 4vw;
      width: calc(100% - 8vw) !important;
    }
    .MuiFormControl-root {
      width: calc(100%) !important;
    }
  }
}