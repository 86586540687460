.CancelSubscription {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 12vh - 10vh - 65px - 14vh);
  .message-content {
    padding-left: 20px;
    padding-right: 20px;
    width: 767px;
    line-height: 1.3;
    text-align: left;
  }
}

.cancel-subsciption-dialog {
  .dialog-box {
    padding-top: 7vh;
    padding-bottom: 9vh;
  }
  .dialog-text {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8vh;
    > div {
      font-family: 'Montserrat', sans-serif;
      color: #707070;
      width: 406px;
    }
  }
  .btn-row {
    display: flex;
    justify-content: center;
    width: 554px;

    button {
      font-family: 'Montserrat', sans-serif;
      margin: 0 40px;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 560px) {
  .cancel-subsciption-dialog {
    .dialog-text {
      > div {
        padding-left: 3vw;
        padding-right: 3vw;
        width: 100%;
      }
    }
    .btn-row {
      width: 100%;
      button {
        margin: 0 6vw;
      }
    }
  }
}