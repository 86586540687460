.AffiliateLanding {
  p{
    text-align: left;
    font-size: 18px;
  }
  .affiliate-name{
    font-size: 68px;
    text-align: left;
  }

  .save-button{
    margin-right: 20px;
  }

  .affiliate-info{
    font-size:35px
  }

  .landing{
    margin: auto;
    width: 95vw;
  }

  @media (min-width: 1200px) {
    .landing{
      width: 85vw;
    }
  }

  .main-screen{
    height: 80vh;
  }

  .testamentary-capacity{
    width: 50vw;
    height: 60vh;
  }

  .how-it-works{
    height: 60vh;
  }

  .why-capacity-vault{
    height: 100vh;
  }

  .flex{
    display: flex;
  }
  
  @media (max-width: 800px) {
    .flex{
      flex-direction: column;
    }
  }

  .how-it-works-button {
    font-size: 20px !important;
    border-radius: 33px !important;
    width: 214px;
    height: 67px;
    text-transform: capitalize !important;
  }

  .step-header{
      color: #2159b3;
      text-align: center;
  }

  .digilegal-company{
    div {
      padding: 35px;
    }
    box-sizing: border-box;
    text-align: center; 
    p {
      text-align: left;
    }
  }

  .about-capacity-vault{
    box-sizing: border-box;
    margin-top: 3vh;
    padding-left: 2vh;
    border-left: solid;
    border-width: 3px;
    border-color: #2159b3;
    flex-wrap: wrap;
    flex-basis: 33%
  }
}