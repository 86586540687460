.Recording {
  .MuiPaper-root {
    padding-left: 42.5%;

    .MuiStepLabel-root {
      position: relative;

      .MuiTypography-root {
        position: absolute;
        top: 24px;
        font-size: 0.8vw;
        left: 0;
        text-align: left;
        width: 300px;
      }
    }
  }

  .recording-subtitle {
    position: absolute;
    z-index: 20;
    bottom: 1.4vw;
    width: 100%;
    text-align: center;

    >span {
      opacity: 0.8;
      background: black;
      font-size: 18px;
      padding-left: 0.5vw;
      padding-right: 0.5vw;
      color: white;
    }
  }

  // subtitle bigger in larger screens
  @media screen and (min-width: 1400px) {
    .recording-subtitle {
      >span {
        font-size: 1.6vw;
      }
    }
  }

  .justify-center {
    justify-content: center;
  }

  .green-submit-button {
    background: #00703C;

    .MuiButton-label {
      text-transform: initial !important;
    }
  }

  .video-button {
    z-index: 2;
  }

  .RecordingVideo {
    position: relative;

    video {
      max-height: 71vh;
    }

    .test-audio-description {
      z-index: 1;
      position: absolute;
      display: flex;
      right: 1vh;
      top: 1vh;
      overflow-x:hidden;
      justify-content: start;

      > div {
        width: 18.8vh !important;
        height: 25vh !important;
        overflow: hidden;
      }
    }

    .alex-video-shadow {
      box-shadow: 5px 5px 15px black;
    }

    .invisible-alex {
      opacity: 0;
    }

    #circle {
      justify-content: center;
      align-items: center;
      position: absolute;
      display: flex;
      width: 100%;
      height: auto;

      >div {
        margin-top: 4vh;
        $size: 52vh;
        display: flex;
        width: $size;
        height: $size;
        max-width: 40vw;
        max-height: 40vw;
        -webkit-border-radius: 40vh;
        -moz-border-radius: 40vh;
        border-radius: 40vh;
        border-color: white;
        border-style: dashed;
        z-index: 1;
      }
    }
  }

  .message-content {
    margin-top: 20vh;
    display: flex;
    justify-content: center;

    >div {
      text-align: left;
      line-height: 1.5;
      width: 740px;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 1060px) {
  .Recording .message-content > div {
    font-size: 25px;
    width: 490px;
    max-width: 100%;
  }
}

// phone
@media screen and (max-width: 560px) {
  .Recording {
    .ft-35 {
      padding-left: 5vw;
      padding-right: 5vw;
      font-size: 20px;
    }
    .phone-tutorial-progress {
      margin-bottom: 2vh;
    }
    .recording-subtitle {
      top: 55vh;
      > span {
        font-size: 4vw;
      }
    }

    #circle {
      >div {
        margin-top: 5vh !important;
        max-width: 90vw !important;
        max-height: 90vw !important;
      }
    }
  }
  .Recording .green-submit-button {
    width: 42vw;
    height: 13vw;
    font-size: 3.6vw!important;
    bottom: 5.011vh!important;
  }
  .Recording .RecordingVideo > div > video {
    max-height: unset !important;
    height: auto !important;
  }
}


// phone landscape
@media (min-aspect-ratio: 10/5) {
  .RecordingVideo.Horizontal {
    .test-audio-description {
      > div {
        width: 30vh !important;
        height: 40vh !important;
      }
    }
    width: 130vh;
    > div > video {
      height: 74vh;
      max-height: 74vh !important;
    }
  }
}