.UpdateUserPassword {
  width: 100% !important;
  max-width: 98vw !important;


  .password-message {
    margin-bottom: 5vh;
  }

  form {
    .MuiGrid-root .MuiGrid-root {
      display: flex !important;
      justify-content: center;
    }
  }
}
.UserPassword {
  margin-top: 5vh;
  margin-bottom: 15vh;
  width: 100%;
  form {
    display: flex;
    justify-content: center;
    .MuiGrid-root {
      display: block;
    }
  }
  .password-message {
    display: flex !important;
    justify-content: center;
    > h5 {
      text-align: left;
      max-width: 450px;
      width: 450px;
    }
  }
  .MuiFormControl-root {
    max-width: 450px;
    width: 450px !important;
  }
  .base-field {
    input {
      font-size: 18px !important;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 1060px) {
  .UserPassword {
    .MuiFormLabel-root  {
      font-size: 16px !important;
    }

    margin-bottom: 10vh;
  }
}

// phone
@media screen and (max-width: 560px) {
  .UserPassword {
    margin-top: 5vh;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 90vw !important;
    .password-message {
      text-align: left;
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 5vh;
      h5 {
        line-height: 1.1 !important;
      }
      span {
        font-size: 17px !important;
      }
    }
    .MuiGrid-root {
      display: block;
      width: 100% !important;
      .MuiGrid-root {
        display: flex;
        justify-content: center;
        width: 100% !important;
        max-width: 100% !important;
        flex-basis: unset !important;
      }
    }
    .MuiFormLabel-root  {
      font-size: 16px !important;
    }

    margin-bottom: 25vh;
    .MuiInputBase-root {
      input {
        font-size: 14px;
      }
    }
  }
}

// wide displays
@media (min-aspect-ratio: 10/5) {
  .UserPassword {
    margin-top: 0 !important;
    margin-bottom: 20vh !important;
  }
}