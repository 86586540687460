.AccountCreation {
  .UserDetailsForm {
    display: flex;
    justify-content: center;
    margin-bottom: 14vh;
  }
  .MuiStep-horizontal {
    padding: 0 !important;
    width: 8%;
    display: flex;
    justify-content: center;
  }
  .MuiPaper-root {
    padding-left: 21%;
    padding-right: 23.5%;
    .MuiStepLabel-root {
      position: relative;
      .MuiTypography-root {
        position: absolute;
        top: 24px;
        font-size: 18px;
        left: 0;
        text-align: left;
        width: 300px;
      }
    }
  }
  .justify-center {
    justify-content: center;
  }
  .message-content {
    width: 700px;
    text-align: left;
    font-size: 35px;
    line-height: 1.5;
  }
  .green-submit-button {
    background: #00703C;
    .MuiButton-label {
      text-transform: initial !important;
    }
  }
  
  .video-button {
    z-index: 25;
  }
}

@media screen and (min-width: 560px) and (max-width: 1500px) {
  .AccountCreation .MuiPaper-root {
      padding-left: 26.5%;
      padding-right: 27%;
      .MuiStepLabel-root {
        .MuiTypography-root {
          top: 24px;
          font-size: 18px;
          left: 0;
          width: 300px;
        }
    }
  }
}

@media screen and (min-width: 560px) and (max-height: 700px) {
  .AccountCreation .message-content {
    font-size: 25px;
    width: 490px;
  }
}

//desktop not high make font smaller
@media screen and (min-width: 860px) and (max-height: 700px) {
  .AccountCreation {
    .MuiBox-root-18 {
      height: 6vh;
    }
  }
}

// phone
@media screen and (max-width: 560px) {
  .AccountCreation {
    height: auto !important;
    .MuiGrid-grid-xs-1 {
      display: none;
    }
    .MuiGrid-grid-xs-10 {
      width: 100%;
      max-width: 100% !important;
      flex-basis: unset !important;
      display: block !important;
      flex-wrap: unset !important;
    }
    .message-content {
      width: auto;
      font-size: 20px;
      width: 90vw;
      .center-text {
        text-align: center;
        font-size: 20px;
        margin: 0;
      }
      p {
        text-align: center;
        line-height: 1.8;
        margin: 0 !important;
        font-size: 20px !important;
        font-family: 'Montserrat', sans-serif;
      }
    }

    .green-submit-button,
    .tutorial-repeat-button {
      width: 42vw;
      font-size: 3.6vw !important;
      bottom: 5.011vh !important;
      height: 13vw;

      span.MuiButton-label {
        text-transform: uppercase !important;
      }
    }

    .green-submit-button {
      right: 5vw !important;
      background: #00703C !important;
    }
  }

  .AccountCreation.AccountPage-0.ActiveStep-0 {
    height: calc(100vh - 8vh - 12vw - 1vh) !important;

    .UserDetailsForm {
      margin-bottom: 0 !important;
    }
    .submit-button-container {
      align-items: center !important;
      height: 14vh;
    }
    .green-submit-button {
      right: 5vw !important;
      bottom: 5vh !important;
    }

    > .MuiGrid-root {
      display: flex !important;
      height: calc(100vh - 8vh - 12vw - 3.5vh - 10vh - 13vw);
      align-items: center;
    }
  }

  .AccountCreation.AccountPage-0.ActiveStep-1 {
    position: relative;
  }

  .AccountCreation.AccountPage-1 {
    height: calc(100vh - 8vh - 12vw - 1vh) !important;
    > .MuiGrid-root {
      height: calc(100vh - 8vh - 12vw - 3.5vh - 10vh - 13vw);
    }
  }
  .AccountCreation.AccountPage-2 {
    > .MuiGrid-root {
      display: flex;
      align-items: center;
    }

    &.ActiveStep-1 {
      position: relative;

      .UserDetailsForm {
        margin-bottom: calc(10vh + 13vw);
      }
    }
  }

  .AccountCreation.AccountPage-2.ActiveStep-0 {
    .MuiGrid-root {
      display: flex;
      align-items: center;
      height: calc(92vh - 12vw - 3.5vh - 10vh - 13vw) !important;
    }
  }
  
  .AccountCreation.AccountPage-3 {
    height: calc(100vh - 8vh - 12vw - 1vh) !important;
  }
  .AccountCreation.AccountPage-4 {
    height: calc(100vh - 8vh - 12vw - 1vh) !important;
    > .MuiGrid-root {
      display: flex;
      align-items: center;
      height: calc(100vh - 8vh - 12vw - 3.5vh - 10vh - 13vw);
    }
  }

  .MuiFormHelperText-contained {
    margin-left: 0 !important;
  }

  .Mui-error {
    font-size: 16px !important;
    font-family: "Montserrat", sans-serif !important;
    color: #f44336;
  }
}