.Demo {
  position: relative;

  .test-audio-description {
    z-index: 10;
    position: absolute;
    display: flex;
    right: 1vh;
    top: 1vh;
    // box-shadow: 5px 5px 15px black;
    justify-content: start;

    >div {
      width: 18.8vh !important;
      height: 25vh !important;
    }
  }

  .demo-next-button{
    position: absolute;
    top:80%;
  }
  
  .green-submit-button {
    background: #00703C;
    color: white;

    .MuiButton-label {
      text-transform: initial !important;
    }
  }

  
  .alex-video-shadow {
    box-shadow: 5px 5px 15px black;
  }

  .invisible-alex {
    opacity: 0;
  }

  .check-screen-subtitle {
    position: absolute;
    z-index: 20;
    bottom: 3vw;
    width: 100%;
    text-align: center;

    >span {
      opacity: 0.8;
      background: rgba(0, 0,0, 0.5);
      font-size: 18px;
      padding-left: 0.5vw;
      padding-right: 0.5vw;
      color: white;
    }
  }

  // subtitle bigger in larger screens
  @media screen and (min-width: 1400px) {
    .check-screen-subtitle {
      >span {
        font-size: 1.6vw;
      }
    }
  }

  .test-audio-record {
    width: auto;
    
    >div {
      width: auto !important;
    }

    video { 
      width: 100%;
    }
  }

  video {
    width: 100%;
  }

  #circle {
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    width: 100%;
    height: auto;

    >div {
      display: flex;
      margin-top: 1.5vw;
      $size: 52vh;
      width: $size;
      height: $size;
      max-width: 40vw;
      max-height: 40vw;
      -webkit-border-radius: 40vh;
      -moz-border-radius: 40vh;
      border-radius: 40vh;
      border-color: white;
      border-style: dashed;
      z-index: 1;
    }
  }
}

// phone
@media screen and (max-width: 2560px) {
  .Demo {
    margin-top: 3vh;

    #circle {
      >div {
        margin-top: 5vh !important;
        max-width: 90vw;
        max-height: 90vw;
      }
    }

    > div {
      position: relative;
    }

    #circle {
      height: auto !important;
      > div {
        top: 0;
      }
    }

    .check-screen-subtitle {
      width: 98%;

      span {
        border-radius: 4vw;
        padding-left: 3vw;
        padding-right: 3vw;
        font-size: 5.4vw;
      }
    }

    video {
      height: unset;
    }

    .test-audio-record {
      max-height: unset !important;
      height: auto !important;

      video {
        height: auto !important;
        max-height: unset !important;
      }
    }

  }
}