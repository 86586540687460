.SubscriptoinCheckOut {
  height: 60vh;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-box {
    padding: 1vh 1vw;
    border-radius: 1vh;
    background: #F3F3F3;
    width: 340px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
}