.UpdatePaymentDetails {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  > div {
    width: 910px;
    text-align: left;

    .upper-container {
      margin: 0 22% 0 18%;
    }

    .title {
      margin-bottom: 1vh;
    }
    .second-title {
      margin: 3vh 0 3vh 0;
      text-align: center;
    }
  }
}

@media screen and (max-width: 560px) {
  .UpdatePaymentDetails {
    .upper-container  {
      margin: 0 4% !important;
      > div {
        font-size: 17px !important;
      }
    }
  }
}