.Login {
    display: flex;
    justify-content: center;
    > div {
      text-align: left;
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      width: 674px;
    }
    p {
        text-align: left !important;
    }

    $form-size: 400px;

    .field-container {
        max-width: 100% !important;
        width: $form-size !important;
    }
    .link-container {
      width: $form-size !important;
      display: flex;
      justify-content: flex-end;
    }
    .sumit-button {
      margin-top: 4vh;
      text-transform: capitalize !important;
      border-radius: 33px;
      font-size: 22px;
      width: 214px;
      height: 67px;
      background: #2259B3 !important;
    }
}

// phone
@media screen and (max-width: 560px) {
  .Login {
    padding-left: 6vw;
    padding-right: 6vw;

    > div {
      padding: 0 !important;
    }

    .link-container {
      width: 88vw !important;
    }
    .sumit-button {
      width: 42vw;
      height: 13vw;
      font-size: 3.6vw !important;

      span.MuiButton-label {
        text-transform: uppercase !important;
      }
    }
  }
}